/***** 1. Content *****/
/* #B2B74E */
a {
    color: #52911E; 
}

body{
	background-color:#fff;
}

body, legend {
    color:#494e55;
}

h1 {
	color:#52911E;
}

hr {
	border-bottom-color: #b2b8c1;
}


/***** 2. Layout *****/


/***** 3. Navigation *****/


/***** 3.a Standard navigation *****/

.dropdown-menu {
  background-color: #7a9cb8;

}

.dropdown-menu > li > a {
  color: #fff;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a,
.dropdown-menu > .active > a:hover {
    background-color: #8caac1;
    color: #fff;
}



.ww-nav a {
	color:#494e55;
}

.ww-nav a:hover {
	color:#52911E;
}


/***** 3.b Tinynav.js *****/

/* Desktop */
.tinynav { 
    background-color:#fff; 
}

/***** 4. Forms *****/

button
{
	background-color: #7599b5;
	color:#fff;
}

button:hover
{
	background-color: #8caac1;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  background-color: #fff;
  border: 1px solid #b2b8c1;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
     -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
       -o-transition: border linear 0.2s, box-shadow linear 0.2s;
          transition: border linear 0.2s, box-shadow linear 0.2s;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: #b2b8c1;
  /* IE6-9 */
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #abc1d2;
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #abc1d2;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #abc1d2;
}



/***** 5. Elements *****/

a.button-link {
  background-color: #7599b5;
  color:#fff;
}

a.button-link:hover {
  background-color: #8caac1;
}


.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
  background-color: #f3f4f5;
}



.nav-tabs > li > a {
  color:#494e55;
}


.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
    background-color: #f3f4f5;
}

.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
    background-color: #f3f4f5;
}


.pagination ul > li > a,
.pagination ul > li > span {
  background-color:  #f3f4f5;
}



/***** 6.b Blog *****/

.widget-blogcategories a:hover,
.widget-blogarchives a:hover
{
  color:#52911E;
}



/***** 6.e Tags *****/


ul.tagcloud a:hover {
	color:#52911E;
}



/***** 6.g.ii Form *****/


.comment-form legend#add-comment, h2.comment-count {
	color:#52911E;
}


/***** 6.h Zones *****/



aside.aside-column article.widget {
	border-top:1px solid #b2b8c1;
}




/***** 7. Misc *****/
.glyphicons i:before {
  color: #494e55;
}


::selection {
  background: #494e55;
  color:#fff;
  }
::-moz-selection {
  background: #494e55;
  color:#fff;
}